import React from "react";
import { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import PortableText from "../components/portableText/portableText";
import CountryCodes from "../components/global/countryCodes";

export default function Contact() {
  useEffect(() => {
    //const Script = document.createElement("script");
    ////id should be same as given to form element
    //const Form = document.getElementById("donateForm");
    //Script.setAttribute(
    //  "src",
    //  "https://checkout.razorpay.com/v1/payment-button.js"
    //);
    //Script.setAttribute("data-payment_button_id", "pl_ISxWTvJem78nwC");
    //Form.appendChild(Script);
    
    const dmScript = document.createElement("script");
    const dmForm = document.getElementById("dmWidgetContainer");    
    dmScript.setAttribute(
      "src",
      "https://danamojo.org/dm/js/widget.js"
    );
    dmForm.appendChild(dmScript);
  
  }, []);

  return (
    <>
      <Layout>
        <SEO />
        <div className="payment-container py-5">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-6 d-flex align-items-center">
                  <div className="text-container">
                    <h1 className="text-light mb-5">
                      Your contribution can change someone's life.
                    </h1>
                    <p className="text-light">
                      All Indian contributions made to Sinhayana Foundation are
                      eligible for tax deduction of 50% u/s 80G of the Income
                      Tax Act 1961.
                    </p>
                    <br />
                    <form id="donateForm"> </form>
                    <hr />
                  </div>
                </div>
                <div className="col-12 col-lg-6 text-center py-5">
                  <img
                    src="/donations_image.png"
                    width="75%"
                    className="rounded-3 my-4"
                  />
                </div>
              </div>              
            </div>
          </div>
        </div>
        <div className="container my-5">
          <p className="text-secondary form-text">
            As per Indian Tax Laws, it is mandatory to have full name and
            address of the contributor else it will be treated as anonymous and
            will be subject to taxation. In case you have a foreign address, we
            will need your passport copy as advised by the FCRA, 2010.
          </p>

          <p className="text-secondary form-text">
            For any queries, write to us on <b>office@sinhayana.org</b> or call
            on
            <b> +91 9990766799</b>
          </p>       
          <div className="row">
            <div id="dmWidgetContainer"> 
                <div id="ngoContentContainer" iNGOId="1054" oDisplay="product" oDisplayTab="once,monthly" oQRCode="YES" ><center><img alt="please wait..." src="https://danamojo.org/dm/css/images/loading.gif"/></center></div>
            </div>
          </div>    
        </div>
      </Layout>

      <style jsx>
        {`
          .payment-container {
            background: #000000;
          }

          .payment-container .card {
            padding: 1.5rem 1rem;
            max-width: 22rem;
            margin: 5rem auto;
          }

          @media only screen and (max-width: 768px) {
            .payment-container .text-container {
              margin-top: 6rem;
            }

            .payment-container .card {
              margin: 2rem auto;
            }
          }
          .row {
            display: flex;
          }
          #ngoContentContainer .row {
          --bs-gutter-x: 2px !important;
          }
          #ngoContentContainer .product_row .product_photo{width:100px !important;}
          .mobilemenu{
            backgroundColor : #000 !important;
            height:100vh !important;
          }        
         
        `}
        
      </style>
    </>
  );
}
